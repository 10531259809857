@import "../src/App.css";
@import "../src/assets/style/forms.css";
@import "../src/assets/style/common";
@import "../src/assets/style/typography.css";

:root {
  --black: #0b0b0d;
  --white: white;

  /* Theme */
  --light: rgb(226, 239, 241);
  --bright: #b6d5e1;
  --blue-gray: rgb(101, 121, 155);
  --purblue: rgb(85, 82, 115);
  --darkMode-bg: #2a2a2a;

  /* User Messages  */
  --err-backgroud: #ffe0e0;
  --err-text: #5d1212;
  /* --err: #b10000; */
  --err: #ff0000;
  --success-background: #d8ffce;
  --success-text: #114c2a;
  --information-background: #cef9ff;
  --information-text: #16405b;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
