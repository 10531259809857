h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  margin: 0;
  /* font-size: 0.75rem; */
}

h2 {
  background: var(--light);
  font-family: monospace;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 1.25rem;
  margin: 0;
  color: black;
  border-bottom: 1px solid var(--purblue);
}

.app-dark h2 {
  background: var(--purblue);
  color: var(--light);
  border-bottom: 1px solid var(--light);
}

h3 {
  margin-bottom: 1rem;
  text-decoration: underline;
  font-size: 1rem;
}

/* @media screen and (min-width: 540px) {
  p {
    font-size: 1rem;
  }
} */
