.App {
  position: relative;
}

.App.app-dark {
  background: var(--purblue);
}

/* Layout  */
header {
  background: var(--bright);
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 2;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.app-dark header {
  background: var(--black);
  color: var(--bright);
}

main {
  max-width: 1140px;
  margin: auto;
  min-height: 100vh;
  height: auto;
  padding: 70px 0 10px;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 50%,
    rgba(182, 213, 225, 1) 80%
  );
}

.app-dark main {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(101, 121, 155) 50%,
    rgba(85, 82, 115, 1) 80%
  );
}

footer {
  background: white;
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  height: 60px;
}

.search-section,
.book-lists-section,
.random-book-section {
  background: var(--bright);
  border-radius: 0.25rem;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  width: 95%;
  margin: 0 auto 2rem;
  max-width: 768px;
  box-shadow: 0 0 5px var(--blue-gray);
  padding-bottom: 1rem;
}

.app-dark .search-section,
.app-dark .book-lists-section,
.app-dark .random-book-section {
  background: var(--black);
  color: var(--light);
  box-shadow: 0 0 5px var(--black);
}

/* .app-dark */

.section-content {
  padding: 0 0.5rem;
  margin-top: 1rem;
}

/* .random-book {
  background: whitesmoke;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.25rem;
}

.app-dark .random-book {
  background: var(--darkMode-bg);
} */

.version {
  position: absolute;
  bottom: 0px;
  right: 5px;
}

.app-dark .version {
  color: var(--light);
}
