/* ============ 
/* Lists  
/* ============ */
.list-wrapper {
  background: var(--light);
  padding: 1rem;
}

.app-dark .list-wrapper {
  background: var(--darkMode-bg);
}

.list-instructions svg {
  margin-right: 5px;
  color: var(--purblue);
}

.app-dark .list-instructions svg {
  color: var(--light);
}

.list-instructions p {
  margin-bottom: 0.5rem;
}

.search-results,
.bucket-list,
.read-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0.25rem;
}

.random-book .book-card-wrapper {
  margin: 1rem auto;
}

@media screen and (min-width: 992px) {
  .search-results,
  .bucket-list,
  .read-list {
    flex-wrap: wrap;
    column-gap: 1rem;
    justify-content: center;
    width: 90%;
    margin: auto;
    row-gap: 1rem;
    overflow-x: hidden;
  }
}

/* ============ 
/* Book Card  
/* ============ */
.book-card-wrapper {
  background: white;
  margin-right: 0.5rem;
  width: 8rem;
  min-width: 8rem;
  box-shadow: 0 0 5px var(--purblue);
}

.app-dark .book-card-wrapper {
  background: var(--darkMode-bg);
  box-shadow: 0 0 5px var(--purblue);
}

.book-card-content {
  background: white;
  padding: 0.25rem;
  border-bottom: 1px solid var(--purblue);
}

.app-dark .book-card-content {
  background: var(--darkMode-bg);
}

.book-card img {
  min-height: 125px;
  height: 135px;
  width: 8rem;
  max-width: 8rem;
  margin: auto;
}

.book-card p {
  margin: 0;
  font-size: 0.65rem;
}

/* ============ 
/* User Messages  
/* ============ */
.user-message-wrapper {
  z-index: 99;
  width: 100%;
  text-align: center;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-message {
  padding: 0.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 540px;
  margin: auto;
  border-radius: 0.25rem;
}

.user-message svg {
  color: var(--err-text);
  padding: 0.25rem;
}

.user-message.error {
  background: var(--err-backgroud);
  color: var(--err-text);
}

.user-message.success {
  background: var(--success-background);
  color: var(--success-text);
}

/* ============ 
/* Modal 
/* ============ */
.modal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 98;
  overflow-y: scroll;
}

.app-dark .modal-wrapper {
  background: var(--darkMode-bg);
  color: var(--bright);
}

.modal {
  max-width: 768px;
  margin: auto;
  height: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 1rem;
}

.modal .close-btn {
  align-self: flex-end;
}

.modal-header {
  text-align: center;
}

.modal-content {
  max-width: 75%;
}

/* ============ 
/* Buttons
/* ============ */
button {
  background: none;
  border: none;
  cursor: pointer;
}

.theme-wrapper {
  border: 3px solid var(--blue-gray);
  background: var(--black);
  border-radius: 1rem;
  position: relative;
  padding: 0.15rem;
  max-width: 50px;
}

.app-dark .theme-wrapper {
  border: 3px solid var(--bright);
}

.theme {
  display: flex;
  justify-content: space-around;
  max-width: 50px;
}

.toggle-theme {
  border-radius: 0.5rem;
  background: var(--blue-gray);
  border: 1px solid var(--blue-gray);
  width: 50%;
  height: 100%;
  position: absolute;
  right: -1px;
  top: -1px;
  border-radius: 1rem;
}

.app-dark .toggle-theme {
  left: -1px;
  background: var(--bright);
  border: 1px solid var(--bright);
}

.theme button {
  color: whitesmoke;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.15rem;
}

.theme svg {
  font-size: 1rem;
}

.app-dark .theme button {
  color: yellow;
}

.list-btns {
  width: 100%;
  padding: 0.5rem 0.25rem;
  background: var(--light);
  border: none;
  text-decoration: underline;
  text-align: left;
}

.app-dark .list-btns {
  background: var(--darkMode-bg);
  color: var(--bright);
}
.btn {
  background: var(--light);
  padding: 0.5rem;
  box-shadow: 1px 1px 5px var(--black);
  font-weight: 800;
  border-radius: 0.25rem;
  color: black;
}

.app-dark .btn {
  background: var(--blue-gray);
}

.btn.responsive {
  width: 100%;
}

.btn-bar {
  display: flex;
  justify-content: space-around;
}

.btn-bar svg {
  color: var(--purblue);
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.app-dark .btn-bar svg {
  color: var(--bright);
}

.btn-bar svg.delete-book {
  color: var(--err);
}

.information-btn {
  position: fixed;
  top: 60px;
  right: 5px;
  background: var(--purblue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.information-btn svg {
  font-size: 2rem;
  color: var(--light);
}

@media screen and (min-width: 768px) {
  .btn.btn.responsive {
    width: initial;
  }
  .btn.btn.responsive:hover {
    background: var(--blue-gray);
    color: white;
  }
}

/* ============ 
/* Misc
/* ============ */
hr {
  margin: 1rem 0;
}

hr.dark {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(101, 121, 155, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.app-dark hr.dark {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(182, 213, 225, 0.75),
    transparent
  );
}
